<template>
    <div class="system-roles list-page page">
        <div class="page--list">
            <div class="scroll-wrapper pd4 set--bottom-foot-padding">
                <div style="margin-bottom:15px;display: flex;align-items: center;">
                    <div style="margin-right: 24px;display: flex;align-items: center;">
                        <span style="color:#606266;padding-right:12px;flex-shrink: 0;">产品名称:</span>
                        <el-select 
                                v-model="searchProductId"
                                filterable
                                placeholder="请输入产品名称"
                                class="width2">
                                <el-option
                                    v-for="p in products"
                                    :key="p.id"
                                    :label="p.name"
                                    :value="p.id"
                                ></el-option>
                            </el-select>
                    </div>
                    <div>
                        <div>
                            <el-input   placeholder="请输入内容" v-model.trim="search_value" class="input-with-select">
                                <el-select style="width:120px;" v-model="search_type" slot="prepend" placeholder="请选择">
                                    <el-option v-for="item in searchTypeEnum" :key="item.value" :label="item.name" :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </div>
                    </div>
                    
                    <el-button style="margin-left:24px;" type="primary" @click.stop="handleSubmit">搜索</el-button>
                    <el-button @click.stop="handleClear">清空</el-button>
                </div>
                <el-table :data="tableData">
                    <el-table-column label="区域" prop="regionName"  align="left"></el-table-column>
                    <el-table-column label="客户编号" prop="cust_code"  align="left"></el-table-column>
                    <el-table-column label="客户姓名" prop="custName"  align="left"></el-table-column>
                    <el-table-column label="EOI产品" prop="productName"  align="left"></el-table-column>
                    <el-table-column label="EOI金额" prop="contractMoney"  align="left"></el-table-column>
                    <el-table-column label="EOI资金类型" prop="typeName"  align="left"></el-table-column>
                    <el-table-column label="EOI进度" prop="checkStateName"  align="left"></el-table-column>

                    <el-table-column label="提交审核时间" prop="submitExamineTime"  width="150" align="left"></el-table-column>
                    <el-table-column label="分公司审核时间" prop="branchExamineTime" width="150" align="left"></el-table-column>

                    <el-table-column label="创建日期" prop="createTimeName"  align="left">
                        <template slot-scope="scope">
                            <span v-html="scope.row.createTimeName"></span>
                        </template>
                    </el-table-column>
                    <el-table-column label="签约销售" prop="saleName"  align="left"></el-table-column>
                    <el-table-column label="操作" prop="search_typeName"  align="left">
                        <template slot-scope="scope">
                            <div style="color: blue;cursor: pointer;" @click="toAuditEoi(scope.row)">去审核</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <list-foot-bar>
                <el-pagination layout="total, sizes, prev, pager, next, jumper" :current-page.sync="pageNum" :page-size="pageSize" :total="total" @size-change="handlePageSizeChange" @current-change="handlePageNoChange"></el-pagination>
            </list-foot-bar>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            tableData: [],
            search_value:'',
            search_type: 1,
            searchTypeEnum: [
                // { name: "全部", value: -1 },
                { name: "客户编号", value: 1},
                { name: "客户姓名", value: 2 },
                { name: "签约销售", value: 3 },
            ],
            pageNum: 1,
            pageSize: 10,
            total: 0,
             searchProductId:'',
            products:[],
        };
    },
    created() {
        if(this.$route.params.search_type>-1){
            this.search_type = Number(this.$route.params.search_type) 
        }
        this.fetchData();
        this.getPorget();
    },
    mounted() {},
    watch: {},
    computed: {},
    methods: {
        async getPorget() {
            const res = await this.$axios({
                url: "/api/orderProcess/getOrderProcessStateParam",
                method: "post"
            });

            if (res.code === 2000) {
                this.products = res.data.productListAll || []
            } else if (res.code !== 1003)
                throw res
        },
        toAuditEoi(data){
            this.$router.push({
                path: `/core/eoi/edit?id=${data.orderId}`,
            });
        },
        handleSubmit() {
            this.pageNum=1
            this.pageSize=10
            this.fetchData();
        },
        handleClear() {
            this.pageNum=1
            this.pageSize=10
            this.search_value = '',
            this.searchProductId = '',
            this.search_type = 1;
            this.fetchData();
        },
        async fetchData() {
            // await setTimeout(() => {
            //     this.tableData =[
            //     {id:1},
            //     {id:2},
            //     {id:3},
            // ]
            // }, 100);

            const loading = this.$loading();
            const res = await this.$axios({
                url: "/api/orderProcess/queryAllAuditList",
                method: "post",
                data: {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    searchValue:this.search_value,
                    searchType:this.search_type,
                    searchProductId:this.searchProductId,
                },
            });
            loading.close();
            if (res.code === 2000) {
                this.tableData = res.data.orderProcessList || [];
                this.total = res.data.count || 0;
            }
        },
        // 翻页相关
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.fetchData();
        },
        // 翻页相关
        handlePageNoChange(no) {
            this.pageNum = no;
            this.fetchData();
        },
    },
};
</script>
<style lang="scss" scoped>
</style>